import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { makeSphinxRequest } from 'services/api/sphinx'
import { extractLimitHeaders } from 'services/api/helpers'
import { SET_FEATURE_LIMIT_EVENT } from 'constants/account/permissions'

// Sphinx is our interal AI Service https://github.com/Junglescout/sphinx

const handleHeaders = (headers, featureLimitKey) => {
  if (!featureLimitKey || !Object.keys(headers).length) return

  const featureLimits = extractLimitHeaders({
    headers: {
      get: key => headers?.[key] || headers?.[key.toLowerCase()]
    }
  })
  if (featureLimitKey && featureLimits) {
    window.dispatchEvent(
      new CustomEvent(SET_FEATURE_LIMIT_EVENT, {
        detail: { featureLimitKey, featureLimits }
      })
    )
  }
}

export const useStreamingRequest = () => {
  const { t } = useTranslation('chatbot')

  const [reader, setReader] = useState()
  const [streamingString, setStreamingString] = useState()
  const [isStreaming, setStreaming] = useState(false)
  const [error, setError] = useState()
  const [options, setOptions] = useState({})

  const setErrorMessage = e =>
    setError(
      e?.message ||
        t(
          'chatbot:Errors.genericRequestError',
          "I can't process your request at the moment. Please try again."
        )
    )

  useEffect(() => {
    if (reader) {
      let text = ''
      const readAndAppend = async () => {
        const { done, value } = await reader.read()

        if (done) {
          setReader()
          setStreaming(false)
          return
        }

        const streamValues = new TextDecoder()
          .decode(value)
          ?.trim()
          ?.split('/end/')
        streamValues.forEach(streamValue => {
          if (!streamValue?.length) return

          let json
          try {
            json = JSON.parse(streamValue) || {}

            if (json.headers) {
              handleHeaders(json.headers, options?.featureLimitKey)
            } else if (json.error) {
              setErrorMessage(json.error)
            } else if (json.token) {
              text += json.token
              setStreamingString(text)
            }

            options?.onStream?.(json)
          } catch {
            /* ignore */
          }
        })

        readAndAppend()
      }

      readAndAppend()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reader])

  const { mutate, isLoading } = useMutation(makeSphinxRequest, {
    onSuccess: async response => {
      setStreaming(true)
      setReader(response)
    },
    onError: () => {
      setErrorMessage()
      setStreaming(false)
    }
  })

  const sendStreamingRequest = ops => {
    reader?.cancel()
    setStreaming(false)
    setError()
    setStreamingString()
    setOptions(ops)
    mutate(ops.request)
  }

  return {
    streamingString,
    sendStreamingRequest,
    isStreaming: isStreaming || isLoading,
    error
  }
}
