import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setGlobalModal, setGlobalModalMetadata } from 'actions/index'

import { UPGRADE_LIMIT_MODAL } from 'constants/feature_limits'
import { SETTINGS_URLS } from 'constants/routes'
import { EUR_CURRENCY_CODE } from 'constants/account'

import { sendUpgradeModalEvent } from 'helpers/segment'
import { getPricingPageUrl } from 'helpers/account'
import { displayUpgradeModal } from 'helpers/account/users'
import {
  getCheckoutUrlOptions,
  getEuLanguageCode,
  currentGroupToNextGroup
} from 'helpers/checkout'

import {
  defaultGroupUpgradeInfo,
  getUpgradePlanDetails
} from 'helpers/feature_limit_modal'

const getDefaultCheckoutUrl = (
  checkoutLocale,
  currency = 'usd',
  newGroup,
  billingFrequency
) => {
  const urls = getCheckoutUrlOptions(checkoutLocale, currency)

  let parsedNewGroup = newGroup?.toLowerCase()
  if (!['growthaccelerator', 'brandowner'].includes(parsedNewGroup)) {
    parsedNewGroup = 'growthaccelerator'
  }

  let parsedBillingFrequency = billingFrequency
  if (!['monthly', 'yearly'].includes(billingFrequency)) {
    parsedBillingFrequency = 'monthly'
  }
  return urls[parsedBillingFrequency][parsedNewGroup]
}

export const useUpgradeModal = parameter => {
  const featureUsageCount = useSelector(state =>
    typeof parameter === 'function'
      ? parameter(state)?.featureUsageCount
      : parameter?.featureUsageCount
  )
  const featureLimit = useSelector(state =>
    typeof parameter === 'function'
      ? parameter(state)?.featureLimit
      : parameter?.featureLimit
  )

  return featureLimit !== -1 && featureUsageCount >= featureLimit
}

export const useFeatureLimitGuard = (initiator, parameter) => {
  const isNotWithinLimit = useUpgradeModal(parameter)
  const dispatch = useDispatch()

  const featureLimitGuard = useCallback(
    cb => (...params) => {
      if (isNotWithinLimit) {
        dispatch(setGlobalModalMetadata({ initiator }))
        dispatch(setGlobalModal(UPGRADE_LIMIT_MODAL))
        sendUpgradeModalEvent({
          testVersion: 'SMB-TEST-4'
        })

        return null
      }

      return cb(...params)
    },
    [dispatch, initiator, isNotWithinLimit]
  )

  return {
    featureLimitGuard,
    isNotWithinLimit
  }
}

/*
  Returns a function to open the right upgrade URL based on the user status and feature flags.
*/
export const useUpgradeURL = () => {
  const membershipInfo = useSelector(state => state.globalData?.membershipInfo)
  const user = useSelector(state => state.globalData?.user)

  const { from_checkout, group } = membershipInfo || {}
  const { checkout_locale, currency, selected_language } = user || {}

  const isUpgrade = displayUpgradeModal(from_checkout)

  const url = isUpgrade
    ? `${window.location.origin}/#${SETTINGS_URLS.subscriptions}`
    : getPricingPageUrl(checkout_locale, currency, selected_language, group)

  const openURL = () =>
    isUpgrade ? window.location.assign(url) : window.open(url, '_blank')

  return { openURL, url }
}

export const useUpgradeCheckoutStaticURL = newGroup => {
  const {
    membershipInfo: {
      billing_frequency: billingFrequency,
      charge_frequency: chargeFrequency,
      from_checkout: fromCheckout
    },
    user
  } = useSelector(state => state.globalData)

  const selectedLanguage = user?.selected_language || 'en-US'
  const checkoutLocale = user?.checkout_locale || 'US'
  const currency = user?.currency || 'usd'

  const { url: mappedUrl, price } = getDefaultCheckoutUrl(
    checkoutLocale,
    currency,
    newGroup,
    billingFrequency
  )

  let url = mappedUrl

  if (displayUpgradeModal(fromCheckout)) {
    url = `${window.location.origin}/#${SETTINGS_URLS.subscriptions}`
  } else if (currency?.toLowerCase() === EUR_CURRENCY_CODE) {
    const lang = getEuLanguageCode(selectedLanguage)
    url = `${url}&lang=${lang}`
  }

  return { url, price, currency, billingFrequency, chargeFrequency }
}

export const useUpgradeCheckoutUrl = (nextUpgradeURL = false) => {
  const { from_checkout, group } = useSelector(
    state => state.globalData?.membershipInfo
  )

  const newGroup = nextUpgradeURL
    ? currentGroupToNextGroup(currentGroupToNextGroup(group?.toLowerCase()))
    : currentGroupToNextGroup(group?.toLowerCase())

  const {
    url: staticUrl,
    price: staticPrice,
    currency: staticCurrency,
    billingFrequency: staticBillingFrequency,
    chargeFrequency: staticChargeFrequency
  } = useUpgradeCheckoutStaticURL(newGroup)

  const upgradeMembershipInfo = useSelector(
    state => state.globalData?.upgradeMembershipInfo
  )
  const nextUpgradeMembershipInfo = useSelector(
    state =>
      state.globalData?.upgradeMembershipInfo?.next_upgrade_membership_info
  )

  const user = useSelector(state => state.globalData?.user)

  let newMembershipGroupInfo = null

  if (nextUpgradeURL && nextUpgradeMembershipInfo) {
    newMembershipGroupInfo = nextUpgradeMembershipInfo
  } else {
    newMembershipGroupInfo = upgradeMembershipInfo
  }

  if (!newMembershipGroupInfo) {
    return {
      url: staticUrl,
      price: staticPrice,
      currency: staticCurrency,
      billingFrequency: staticBillingFrequency,
      chargeFrequency: staticChargeFrequency,
      nextGroupCode: newGroup
    }
  }

  const selectedLanguage = user?.selected_language
  const lang = selectedLanguage !== 'zh-Hant' ? selectedLanguage : 'en-US'

  let url = null

  if (displayUpgradeModal(from_checkout)) {
    url = `${window.location.origin}/#${SETTINGS_URLS.subscriptions}`
  } else {
    url = `${window.location.origin}/#/registrations?mt=${newMembershipGroupInfo.token}&lang=${lang}`
  }

  const {
    price,
    stripe_plan: { currency },
    billing_frequency,
    charge_frequency
  } = newMembershipGroupInfo

  return {
    url,
    price: price / 100.0,
    currency,
    billingFrequency: billing_frequency,
    chargeFrequency: charge_frequency,
    nextGroupCode: newGroup
  }
}

export const useUpgradeFeatureLimitInfo = () => {
  const membershipInfo = useSelector(state => state.globalData?.membershipInfo)
  const upgradeMembershipInfo = useSelector(
    state => state.globalData?.upgradeMembershipInfo
  )

  const {
    url,
    price,
    currency,
    billingFrequency,
    chargeFrequency
  } = useUpgradeCheckoutUrl()

  let upgradePlanDetails = null

  upgradePlanDetails = upgradeMembershipInfo
    ? getUpgradePlanDetails(
        upgradeMembershipInfo?.access_right?.permissions,
        membershipInfo.group
      )
    : defaultGroupUpgradeInfo(membershipInfo?.group, false)

  return {
    upgradePlanDetails,
    upgradeCheckoutDetails: {
      url,
      price,
      currency,
      billingFrequency,
      chargeFrequency
    }
  }
}
