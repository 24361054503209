import { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isMobile } from 'react-device-detect'

import { useStreamingRequest } from 'hooks/sphinx'
import { useSelectedAmazonSellerAccount } from 'hooks/amazon_seller_account'

import {
  makeSphinxRequest,
  getQuestionsEndpoint,
  createQuestionEndpoint,
  deleteQuestionsEndpoint,
  createAnswerEndpoint,
  updateAnswerEndpoint,
  createReportEndpoint
} from 'services/api/sphinx'
import { queryClient } from 'services/query_client'
import { sendSafeSegmentTrackEvent } from 'services/segment'

const QUERY_KEY = 'sphinxQuestions'

export const useChatHistory = () => {
  const [isLoadingHistory, setLoadingHistory] = useState(true)

  const { data, isLoading } = useQuery(QUERY_KEY, {
    queryFn: () => makeSphinxRequest(getQuestionsEndpoint()),
    refetchOnWindowFocus: false,
    onSettled: () => setLoadingHistory(false)
  })
  const questions = data || []

  return {
    questions,
    isLoadingHistory: isLoadingHistory || isLoading
  }
}

/*
 * Update the questions in the cache
 * Removes questions without an id
 * Adds or replaces question with the same id
 */
const updateQuestions = question =>
  queryClient.setQueryData(QUERY_KEY, queryData => {
    /* queryData schema => { data: [...] } */

    // Get query data and remove questions without an id.
    let data = queryData?.data?.filter(o => Boolean(o.id)) || []

    // Check if the question already exists in the array
    const exists = data.find(o => o.id === question.id)

    if (exists) {
      // Iterate over all the existing questions and replace question with the same id
      data = data.map(q => (q.id === question.id ? question : q))
    } else {
      // Add the new question to the array
      data.push(question)
    }

    return { data }
  })

export const useChat = () => {
  const {
    amazon_seller_account_id,
    marketplace_id
  } = useSelectedAmazonSellerAccount()

  const {
    sendStreamingRequest,
    streamingString,
    isStreaming,
    error
  } = useStreamingRequest()

  const createAnswer = body =>
    sendStreamingRequest({
      request: createAnswerEndpoint(body),
      onStream: json => {
        if (json.question) updateQuestions(json.question)
      }
    })

  const sendQuestion = ({ question }) => {
    updateQuestions({ text: question, answers: [] })
    sendStreamingRequest({
      request: createQuestionEndpoint({
        question,
        context: {
          amazon_seller_account_id,
          marketplace_id
        }
      }),
      featureLimitKey: 'aiFeaturesChat',
      onStream: json => {
        if (json.question) {
          const { text, type, rephrasedText, answers } = json.question
          const answer = answers?.[0] || {}

          sendSafeSegmentTrackEvent('Chatbot Message Sent', {
            text,
            rephrasedText,
            classification: type,
            langsmithId: answer.id,
            endpoint: answer.clientRequestSpec?.id,
            device: isMobile ? 'mobile' : 'desktop'
          })

          updateQuestions(json.question)
        }
      }
    })
  }

  const createReport = body => {
    updateQuestions({
      text: body.question,
      answers: body.answer?.text?.length
        ? [{ ...body.answer, type: 'report' }]
        : []
    })
    sendStreamingRequest({
      request: createReportEndpoint(body),
      onStream: json => {
        if (json.question) updateQuestions(json.question)
      }
    })
  }

  return {
    sendQuestion,
    createAnswer,
    createReport,
    streamingString,
    isStreaming,
    error
  }
}

export const useAnswerUpdate = () => {
  const { mutate } = useMutation(body =>
    makeSphinxRequest(updateAnswerEndpoint(body))
  )

  const updateAnswer = (params, question) => {
    mutate(params, {
      onSuccess: data => data?.question && updateQuestions(data.question)
    })
    if (question) updateQuestions(question)
  }

  return { updateAnswer }
}

export const useClearChatHistory = () => {
  const { mutate } = useMutation(() =>
    makeSphinxRequest(deleteQuestionsEndpoint())
  )

  const clearChatHistory = () => {
    queryClient.setQueryData(QUERY_KEY, () => [])
    mutate()
  }

  return { clearChatHistory }
}
